import React from 'react';
import '../../styles/line.css';

function Line() {
    return (
        <span className='line container-xl'>
        </span>
    );
}

export default Line;
